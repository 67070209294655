const topMenuBarItems = [
  // {
  //   href: '/landing-page5',
  //   title: 'Home',
  //   isCurrent: true,
  // },
  //   submenu: [
  //     // {
  //     //   href: '/landing-page1',
  //     //   title: 'Home One'
  //     // },
  //     // {
  //     //   href: '/landing-page2',
  //     //   title: 'Home Two'
  //     // },
  //     // // {
  //     //   href: '/landing-page3',
  //     //   title: 'Home Three'
  //     // },
  //     // {
  //     //   href: '/landing-page4',
  //     //   title: 'User Anaytics'
  //     // },
  //     {
  //       // href: '/landing-page5',
  //       // title: 'Home'
  //     },
  //     // {
  //     //   href: '/landing-page6',
  //     //   title: 'Predective Analytics'
  //     // },
  //     // {
  //     //   href: '/landing-page7',
  //     //   title: 'Sentiment Analysis'
  //     // },
  //     // {
  //     //   href: '/landing-page8',
  //     //   title: 'User Behaviour Analysis'
  //     // },
  //     // {
  //     //   href: '/landing-page9',
  //     //   title: 'Data Scientist Profile'
  //     // },
  //     // {
  //     //   href: '/landing-page10',
  //     //   title: 'Data Visualization Service'
  //     // },
  //     // {
  //     //   href: '/landing-page11',
  //     //   title: 'Analytics In Marketing'
  //     // }
  //   ]
  // },
  // {
  //   href: '/about-us',
  //   title: 'About Us ',
  //   submenu: [
  //     {
  //       href: '/about-us',
  //       title: 'About Us'
  //     },


  //   ]
  // },

  {
    href: '/home',
    // href: '',

    // title: 'Home',
    // submenu : [

    //   {
    //     href: '/landing-page6',
    //     title: 'Strategy'
    //   },

    // ]
  },
  {
    href: '/aboutus',
   title: 'About Us'
  },

  {
    href: '/ourwork',
    // href: '',

    title: 'Our Work',
    // submenu : [

    //   {
    //     href: '/landing-page6',
    //     title: 'Strategy'
    //   },

    // ]
  },

  // {
  //   href: '/home',
  //   // href: '',

  //   title: 'Home',
  //   // submenu : [

  //   //   {
  //   //     href: '/landing-page6',
  //   //     title: 'Strategy'
  //   //   },

  //   // ]
  // },
  // {
  //   href: '/our-cases/portfolio-2-columns',
  //   // href: '',

  //   title: 'Portfolio',
    // submenu: [

    //       {
    //         href: '/our-cases/portfolio-2-columns',
    //         title: 'Portfolio'
    //       }
    //       // {
    //       //   href: '/our-cases/portfolio-3-columns',
    //       //   title: 'Portfolio 3 Columns'
    //       // },
    //       // {
    //       //   href: '/our-cases/portfolio-4-columns',
    //       //   title: 'Portfolio 4 Columns'
    //       // },
    //       // {
    //       //   href: '/our-cases/portfolio-5-columns',
    //       //   title: 'Portfolio 5 Columns'
    //       // },
    //       // {
    //       //   href: '/our-cases/portfolio-no-space',
    //       //   title: 'Portfolio No Space'
    //       // }
    //     ,

    //   {
    //     href: '/our-cases/masonry-2-portfolio',
    //     title: 'Masonry 2 Portfolio'
    //   },
    //   // {
    //   //   href: '/our-cases/masonry-3-portfolio',
    //   //   title: 'Masonry 3 Portfolio'
    //   // },
    //   // {
    //   //   href: '/our-cases/masonry-no-space',
    //   //   title: 'Masonry No Space'
    //   // },
    //   // {
    //   //   href: '/our-cases/portfolio-details',
    //   //   title: 'Portfolio Details'
    //   // }
    //     ]
  // },

  {
    href: '/ourteam',
    title: 'Our Team',
    // submenu: [
    //   {
    //     href: '',
    //     title: 'Our Team'
    //   },
    //   // {
    //   //   href: '#blog-grid',
    //   //   title: 'Blog Grid',
    //   //   submenu: [
    //   //     {
    //   //       href: '/blog/blog-one-column-grid',
    //   //       title: 'One Column Blog'
    //   //     },
    //   //     {
    //   //       href: '/blog/blog-two-column-grid',
    //   //       title: 'Two Column Blog'
    //   //     },
    //   //     {
    //   //       href: '/blog/blog-three-column-grid',
    //   //       title: 'Three Column Blog',
    //   //     }
    //   //   ]
    //   // },
    //   // {
    //   //   href: '/blog/blog-right-sidebar-grid-one',
    //   //   title: 'Right SideBar Grid',
    //   //   submenu: [
    //   //     {
    //   //       href: '/blog/blog-right-sidebar-grid-one',
    //   //       title: 'Right Sidebar Grid 1'
    //   //     },
    //   //     {
    //   //       href: '/blog/blog-right-sidebar-grid-two',
    //   //       title: 'Right Sidebar Grid 2'
    //   //     }
    //   //   ]
    //   // },
    //   // {
    //   //   href: '/blog/blog-left-sidebar-grid-one',
    //   //   title: 'Left SideBar Grid',
    //   //   submenu: [
    //   //     {
    //   //       href: '/blog/blog-left-sidebar-grid-one',
    //   //       title: 'Left Sidebar Grid 1'
    //   //     },
    //   //     {
    //   //       href: '/blog/blog-left-sidebar-grid-two',
    //   //       title: 'Left Sidebar Grid 1'
    //   //     }
    //   //   ]
    //   // },
    //   // {
    //   //   href: '/blog/blog-details',
    //   //   title: 'Blog Details'
    //   // }
    // ]
  },



  // {
  //   href: '/newsandmedia',
  //   title: 'Contact Us',
    // submenu: [
    //   {
    //     href: '/pages/our-team',
    //     title: 'Our Team'
    //   },
    //   {
    //     href: '/pages/client',
    //     title: 'Clients'
    //   },
    //   {
    //     href: '/pages/career',
    //     title: 'Careers',
    //   },
    //   {
    //     href: '/pages/contact-us',
    //     title: 'Contact Us',
    //   }
    // ]
  // },

  {
    href: '/pages/contact-us',
    // href: '',

    title: 'Contact Us',
    // submenu : [

    //   {
    //     href: '/pages/contact-us',
    //     title: 'Contact Us'
    //   },

    // ]
  },

  // {
    // href: '/landing-page6',
    // href: '/pages/home',

    // title: 'Disclaimers',
    // submenu : [

    //   {
    //     href: '/landing-page6',
    //     title: 'Disclaimers'
    //   },

    // ]
  // },
];

const servicesMenu =  [
  {
    href: '/services/data-analytics',
    title: 'Data Analytics'
  },
  {
    href: '/services/managed-analytics',
    title: 'Managed Analytics'
  },
  {
    href: '/services/big-data-services',
    title: 'Big Data Services'
  },
  {
    href: '/services/data-science-consulting',
    title: 'Data Science Consulting'
  },
  {
    href: '/services/business-intelligence',
    title: 'Business Intelligence'
  },
  {
    href: '/services/data-visualization-services',
    title: 'Data Visualization'
  },
  {
    href: '/services/data-management',
    title: 'Data Management'
  },
  {
    href: '/services/artificial-intelligence',
    title: 'Artificial Intelligence'
  }
];

export { servicesMenu, topMenuBarItems };
